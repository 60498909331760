@import "./assets/common";

.font-thin,
.thin {
  font-weight: 100;
}
.font-xlight,
.xlight {
  font-weight: 200;
}
.font-light,
.light {
  font-weight: 300;
}
.font-regular,
.regular {
  font-weight: 400;
}
.font-medium,
.medium {
  font-weight: 500;
}
.font-semibold,
.semibold {
  font-weight: 600;
}
.font-bold,
.bold {
  font-weight: 700;
}
.font-xbold,
.xbold {
  font-weight: 800;
}
.font-black,
.black {
  font-weight: 900;
}

::-moz-selection {
  background: $colorBgSelection !important;
}
::selection {
  background: $colorBgSelection !important;
}
body {
  font-family: Inter, Roboto, Helvetica, Arial, sans-serif;  // override font-family của ng-zorro-antd
  line-height: 1.2;
  background-color: #F0F2F5;
  color: $colorBlack;
}
.h2 {
  font-size: 18px;
  font-weight: 500;
  color: black;
}
// .green {
//   color: $colorGreen;
// }
a:hover {
  opacity: 0.75;
}
a:active {
  opacity: 0.5;
}
a:visited {
  color: #0278d0;
}
// a.green:hover {
//   color: $colorGreenHover;
// }
// a.green:active {
//   color: $colorGreenActive;
// }
.grey {
  color: $colorGrey;
}
.single-line {
  white-space: nowrap;
}
// .bg-purple {
//   background-color: $colorPurple;
// }
.section-label-row {
  display: flex;
  margin-bottom: 25px;
}
.admin-spinner {
  stroke: $colorPrimary;
}
// .btn {
//   font-size: 14px !important;
//   cursor: pointer;
// }
// input {
//   font-size: 14px;
// }
// ul {
//   font-size: 14px !important;
// }
// textarea {
//   font-size: 14px !important;
// }
button .ic-left {
  margin-right: 10px;
}
button .ic-left.anticon {
  margin-right: 5px;
}
button .ic-right {
  margin-left: 10px;
}
button.text-icon .fa {
  margin-right: 10px;
}
.loading.md {
  font-size: 20px;
}
.loading.lg {
  font-size: 30px;
}
.loading.primary {
  color: $colorPrimary;
}
.loading-container {
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-nodata {
  align-items: center;
  justify-content: center;
  color: #5e5e5e;
}
.nodata {
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 500px;
  font-size: 24px;
  color: $colorGrey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nodata > i.anticon-loading {
  color: $colorPrimary;
  font-size: 40px;
}
.nodata > i.anticon-search {
  margin-right: 15px;
}
.bg-blur {
  background-color: #00000066;
  backdrop-filter: blur(10px);
}
.courier {
  font-family: "Courier New", Courier, monospace;
}
.strikethrough {
  text-decoration: line-through;
}
a.strikethrough:hover {
  text-decoration: line-through;
}
.f10 {
  font-size: 10px;
}
.f11 {
  font-size: 11px;
}
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f17 {
  font-size: 17px;
}
.f16b {
  font-size: 16px;
  font-weight: 700;
}
.f16m {
  font-size: 16px;
  font-weight: 500;
}
.f18 {
  font-size: 18px;
}
.f18m {
  font-size: 18px;
  font-weight: 500;
}
.f18b {
  font-size: 18px;
  font-weight: 700;
}
.f20 {
  font-size: 20px;
}
.f50 {
  font-size: 50px;
}
.wh16 {
  width: 16px;
  height: 16px;
}
.space {
  flex: 1;
}
td.align-top {
  vertical-align: top;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
img.noselect {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.row-border-bottom {
  border-bottom: 1px solid $colorBorder;
}
input.center {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
// For password dot
input[type="password"]:not(:placeholder-shown) {
  font-family: Verdana, sans-serif;
  letter-spacing: 3px;
}
// override input's placeholder color -->
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $colorPlaceHolder !important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $colorPlaceHolder !important;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $colorPlaceHolder !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $colorPlaceHolder !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $colorPlaceHolder !important;
}
::placeholder {
  /* Most modern browsers support this now. */
  color: $colorPlaceHolder !important;
}
// <-- override input's placeholder color

// .sidebar-bg {
//   background-color: $colorPurple;
// }

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}
i.disabled {
  pointer-events: none;
  cursor: default;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
a.inherit {
  color: inherit;
}
a.underline {
  text-decoration: underline;
}
button:focus {
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.w50 {
  width: 50px;
}
.w100 {
  width: 100px;
}
.w110 {
  width: 110px;
}
.w150 {
  width: 150px;
}
.w180 {
  width: 180px;
}
.w200 {
  width: 200px;
}
.mw500 {
  max-width: 500px;
}
.mw600 {
  max-width: 600px;
}
.top5{
  margin-top: 5px;
}
.top8 {
  margin-top: 8px;
}
.top10 {
  margin-top: 10px;
}
.top15 {
  margin-top: 15px;
}
.top20 {
  margin-top: 20px;
}
.top25 {
  margin-top: 25px;
}
.top30 {
  margin-top: 30px;
}
.top40 {
  margin-top: 40px;
}
.bottom0 {
  margin-bottom: 0px;
}
.bottom5 {
  margin-bottom: 5px;
}
.bottom10 {
  margin-bottom: 10px;
}
.bottom15 {
  margin-bottom: 15px;
}
.bottom20 {
  margin-bottom: 20px;
}
.bottom30 {
  margin-bottom: 30px;
}
.bottom40 {
  margin-bottom: 40px;
}
.bottom80 {
  margin-bottom: 80px;
}
.right3 {
  margin-right: 3px;
}
.right5 {
  margin-right: 5px;
}
.right10 {
  margin-right: 10px;
}
.right15 {
  margin-right: 15px;
}
.right20 {
  margin-right: 20px;
}
.right30 {
  margin-right: 30px;
}
.right40 {
  margin-right: 40px;
}
.left20 {
  margin-left: 20px;
}
.left15 {
  margin-left: 15px;
}
.left10 {
  margin-left: 10px;
}
.left5 {
  margin-left: 5px;
}
.visible {
  opacity: 1;
}
.invisible {
  opacity: 0;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flexwrap {
  display: flex;
  flex-wrap: wrap;
}
.gap5{
  gap: 5px
}
.gap10 {
  gap: 10px;
}
.gap15 {
  gap: 15px;
}
.gap20 {
  gap: 20px;
}
.upper {
  text-transform: uppercase;
}
.pointer {
  cursor: pointer;
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  padding: 0px;
  border-radius: 50% !important;
  transition: border 0.3s ease;
}
.avatar-white {
  border: 1px solid $colorBorder;
}
.avatar30 {
  width: 30px;
  height: 30px;
}
.attached-file {
  width: fit-content;
  display: flex;
  align-items: center;
}
.center-children {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.center-vertical {
  display: flex;
  align-items: center;
}
.match-parent {
  width: 100%;
  height: 100%;
}
.full-w {
  width: 100%;
}
.float-right {
  float: right;
}
.full-h {
  height: 100%;
}
.overlay-container {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.success {
  color: $colorSuccessV2;
}
.danger {
  color: $colorDanger;
}
a.danger:hover {
  color: $colorDanger;
}
a.danger:active {
  color: $colorDanger;
}
.label-mark-required {
  color: $colorDanger;
}
.label-mark-required::after {
  padding-left: 4px;
  content: "*";
}
.overlay-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.disabled:hover {
  cursor: not-allowed;
}
div.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
div.disabled.btn {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
}
.clickable.danger {
  color: $colorDanger;
}
.clickable {
  cursor: pointer;
  // color: $colorBodyText;
}
.clickable:not(.disabled):hover {
  opacity: 0.65;
}
.clickable:not(.disabled):active {
  opacity: 0.35;
}
.notclickable {
  cursor: default;
}
.notclickable:hover {
  opacity: 1 !important;
}
.notclickable a {
  cursor: default;
}
.notclickable a:hover {
  opacity: 1 !important;
}
.product-box > .overlay-bottom {
  top: 136px;
}
.separator {
  background-color: $colorBorder;
}
.separator.h {
  width: 100%;
  height: 1px;
}
.separator.v {
  width: 1px;
  height: 100%;
}
////////////////// admin /////////////////////////
.dashboard-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  margin-bottom: 20px;
}
.dashboard-page-header > button {
  margin-right: 10px;
}
.dashboard-child-container {
  padding: 10px 20px 60px 20px;
  border-radius: 2px;
  min-height: 100%;
}
.dashboard-child-container.bg-white {
  background-color: white;
}
.dashboard-child-container.no-padding {
  padding: 0;
}
.link.primary {
  color: $colorPrimary;
}
.link:not(.primary) {
  color: $colorBodyTextAdmin !important;
  transition: all 0.3s;
}
.link:hover:not(.nolink) {
  text-decoration: underline;
}
.link-disabled {
  color: $formInputColorDisabled !important;
}
.nolink {
  color: inherit;
  cursor: default;
}
.nolink:hover {
  color: inherit;
}
.fake-input-disabled {
  box-sizing: border-box;
  color: $formInputColorDisabled;
  background-color: $colorBgInputDisabled;
  border: 1px solid $colorBorder;
  line-height: 1.5;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: $inputHeight;
  padding: 4px 11px;
  border-radius: $commonRadius;
}
// From Bootstrap v4.0.0 -->
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 100%; // 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%; //720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.rounded-circle {
  border-radius: 50%;
}
.text-center {
  text-align: center;
}
.warning {
  color: $colorWarning;
}
.primary {
  color: $colorPrimary;
}
// <-- From Bootstrap

// cdk drag drop -->
.drag-drop-list {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  display: block;
  overflow: hidden;
}
.drag-drop-list-horizontal {
  display: flex;
  overflow-x: auto;
}
.drag-drop-item {
  padding: 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}
.drag-drop-item-disabled {
  cursor: default;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-placeholder {
  opacity: 0.3;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.drag-drop-item:last-child {
  border: none;
}
.drag-drop-list.cdk-drop-list-dragging .drag-drop-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// <-- cdk drag drop

img.svg-icon {
  width: 22px;
  height: 22px;
}
img.svg-icon-left {
  margin-right: 6px;
}
button.svg-icon {
  display: inline-flex;
  align-items: center;
}
button.svg-icon > img {
  width: 22px;
  height: 22px;
}
img.play_circle {
  content: url("./assets/img/play_circle.svg");
}
img.pause_circle {
  content: url("./assets/img/pause_circle.svg");
}
img.file-arrow-up {
  content: url("./assets/img/file-arrow-up.svg");
}
img.file-arrow-down {
  content: url("./assets/img/file-arrow-down.svg");
}
img.coin {
  content: url("./assets/img/coin.svg");
}
img.database {
  content: url("./assets/img/database.svg");
}
img.plus-circle {
  content: url("./assets/img/plus-circle.svg");
}
img.mappin {
  content: url("./assets/img/mappin.svg");
}
img.truck {
  content: url("./assets/img/truck.svg");
}
img.truck1 {
  content: url("./assets/img/truck1.svg");
}
img.truck2 {
  content: url("./assets/img/truck1.svg");
  width: 18px;
}
img.truck3 {
  content: url("./assets/img/truck1.svg");
  width: 15px;
}
img.pickup-time {
  content: url("./assets/img/vector-pickup-time.svg");
  width: 11px;
}
img.dropoff-time {
  content: url("./assets/img/vector-dropoff-time.svg");
  width: 11px;
}
img.ismultistop-icon {
  content: url("./assets/img/dispatch_v2_ismultistop.svg");
  width: 11px;
}
img.delete {
  content: url('./assets/img/delete.svg');
}
// status
td.width-fit-content {
  width: 1%;
  white-space: nowrap;
}
.order-status {
  width: fit-content;
  height: 28px;
  border-radius: 5px;
  border: 1px solid $colorBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
}
.order-status.job {
  width: 90px;
}
.order-status .anticon {
  margin-left: 10px;
}
.order-status.status-pickupSuccessful,
.order-status.status-dropoffSuccessful,
.order-status.status-succeeded,
.order-status.status-complete,
.order-status.status-disposed,
.order-status.status-completed {
  border-color: $colorSuccessV2;
  color: $colorSuccessV2;
}
.order-status.status-pickupFailed,
.order-status.status-dropoffFailed,
.order-status.status-returned,
.order-status.status-failed,
.order-status.status-lost,
.order-status.status-issue,
.order-status.status-canceled-tonu,
.order-status.status-canceled {
  border-color: $colorDangerV2;
  color: $colorDangerV2;
}
.order-status.status-dispatched,        // dispatched means inRouteToPickup
.order-status.status-inRouteToDropoff,
.order-status.status-inRouteToReturn,
.order-status.status-inprogress,
.order-status.status-enroute {
  border-color: #1890ff;
  color: #1890ff;
}
.order-status.status-arrivedAtPickup,
.order-status.status-arrivedAtDropoff,
.order-status.status-arrivedAtReturn,
.order-status.status-arrived {
  border-color: #faad14;
  color: #faad14;
}
.order-status.status-removed {
  border-color: $colorDangerV2;
  color: $colorDangerV2;
  opacity: 0.6;
}
.order-status.status-created {
  border-color: #d9d9d9;
  color: #888;
}
.order-status .icon-hidden {
  display: none;
}

.status-pickupSuccessful,
.status-dropoffSuccessful,
.status-succeeded,
.status-complete,
.status-completed {
  border-color: $colorSuccessV2;
  color: $colorSuccessV2;
}
.status-pickupFailed,
.status-dropoffFailed,
.status-returned,
.status-lost,
.status-issue,
.status-failed,
.status-canceled-tonu,
.status-canceled {
  border-color: $colorDangerV2;
  color: $colorDangerV2;
}
.status-dispatched,        // dispatched means inRouteToPickup
.status-inRouteToDropoff,
.status-inRouteToReturn,
.status-inprogress,
.status-enroute {
  border-color: #1890ff;
  color: #1890ff;
}
.status-arrivedAtPickup,
.status-arrivedAtDropoff,
.status-arrivedAtReturn,
.status-arrived {
  border-color: #faad14;
  color: #faad14;
}
.status-removed {
  border-color: $colorDangerV2;
  color: $colorDangerV2;
  opacity: 0.6;
}
.status-created {
  border-color: #d9d9d9;
  color: #888;
}

.nz-icon-inline {
  margin: auto 10px auto 0px;
  font-size: 16px;
  &.large {
    font-size: 20px;
  }
}

.ant-modal {
  &.modal-lg {
    width: 800px !important;
  }
  &.modal-xl {
    width: 1200px !important;
  }
  &.modal-xxl {
    width: 95% !important;
    top: 30px;
  }
  &.modal-auto {
    width: auto !important;
  }
}

.box-with-title {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  position: relative;
  margin-bottom: 20px;
  .box-title {
    font-size: 16px;
    line-height: 32px;
    padding: 0 10px;
    position: absolute;
    top: -18px;
    background: #fff;
    left: 20px;
    font-weight: 500;
  }
  .box-content {
    padding: 16px;
  }
}
.quote-filter {
  .ant-input-affix-wrapper {
    background-color: $colorWhite;
    border: 1px solid $colorBorder;
  }
  .ant-input-affix-wrapper.error {
    border: 1px solid $colorDanger !important;
  }
  .input-select.error {
    border: 1px solid $colorDanger !important;
  }
  ::placeholder {
    color: #92929D !important;
  }
}

$quote-text-color: #010101;
$select-background-color: #ffffff;
$quote-item-color: #92929D;
$quote-item-selected-color: white;
$quote-item-selected-background-color: #0768cf;
$quote-item-button-selected: #0768cf;
$include-image-filter: brightness(0) invert(1);
$quote-bg-color-shipment-pickup: white;
$quote-item-shiment-time: #454545;
$quote-item-shipment-time-image-filter: brightness(0) invert(0.4);
$quote-input-select-border: #30353F;
$result-quote-text-1: $quote-text-color;
$result-quote-text-2: #30353F;

.weight-unit {
  background: #525252;
  border-radius: 4px;
  font-size: 12px;
  .weight-unit-item {
    padding: 1px 8px;
    cursor: pointer;
    color: white;
  }
  .weight-unit-item.selected {
    border-radius: 4px;
    background: $quote-item-button-selected;
    color: white;
  }
}

.ant-dropdown {
  .backgroup-dropdown-relative {
    padding: 20px 20px 60px 20px;
    background-color: $colorWhite;
    border: 1px solid $colorBorder;
    border-radius: 4px;
    .item {
      background: $colorBgSelection;
      border: 1px solid $colorBorder;
      border-radius: 4px;
      text-align: center;
      padding: 1px 3px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.1px;
      // color: @quote-item-color;
      cursor: pointer;
      margin: 0px 2px 10px 2px;
      width: fit-content;
    }
    .item.selected {
      background: $quote-item-selected-background-color;
      color: $quote-item-selected-color;
    }
    button.item {
      width: 100%;
    }
    button.item.selected {
      background: $quote-item-button-selected;
      color: $quote-item-selected-color;
    }
    .footer {
      position: absolute;
      bottom: 20px;
      right: 0px;
      left: 0px;
      text-align: center;
      .btn-done {
        color: #F8C60D;
        border: 1px solid #F8C60D;
        border-radius: 10px;
        align-items: center;
        width: 120px;
      }
    }
  }
  .quote-backgroup-dropdown.hotlist {
    padding: 20px 20px 60px 20px !important;
    min-width: 200px !important;
  }

  .quote-backgroup-dropdown.package {
    padding: 20px 20px 60px 20px !important;
  }
  .quote-backgroup-dropdown {
    padding: 50px 20px 60px 20px;
    display: flex;
    flex-flow: wrap;
    position: absolute;
    background-color: #ffffff;
    border: 1px solid $colorBorder;
    border-radius: 4px;
    .item {
      background: $select-background-color;
      border: 1px solid $colorBorder;
      border-radius: 4px;
      text-align: center;
      padding: 1px 3px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.1px;
      color: $quote-item-color;
      cursor: pointer;
      margin: 0px 2px 10px 2px;
      width: fit-content;
    }
    .item.selected {
      background: $quote-item-selected-background-color;
      color: $quote-item-selected-color;
    }
    button.item {
      width: 100%;
      color: $quote-text-color;
    }
    button.item.selected {
      background: $quote-item-button-selected;
      color: $quote-item-selected-color;
    }
    .footer {
      position: absolute;
      bottom: 20px;
      right: 0px;
      left: 0px;
      text-align: center;
      .btn-done {
        background-color: #F8C60D;
        border: 1px solid #F8C60D;
        border-radius: 10px;
        align-items: center;
        width: 120px;
        color: #010101;
      }
      .btn-done:hover {
        color: #010101;
      }
    }

  }

  .backgroup-dropdown {
    padding: 60px 20px 60px 20px;
    display: flex;
    flex-flow: wrap;
    position: static;
    background-color: #FFFFFF;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    
    .item {
      background: #F8FAFC;
      border: 1px solid #d9d9d9;
      border-radius: 100px;
      text-align: center;
      padding: 1px 7px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.1px;
      color: #92929D;
      cursor: pointer;
      margin: 0px 2px 10px 2px;
      width: fit-content;
    }
    .item.selected {
      background: #0F172A;
      color: white;
    }
    button.item {
      width: 100%;
      color: #334155;
    }
    button.item.selected {
      background: #0F172A;
      color: white;
    }
    .footer {
      position: absolute;
      bottom: 20px;
      right: 0px;
      left: 0px;
      text-align: center;
      .btn-done {
        color: white;
        border: 1px solid #0F172A;
        border-radius: 10px;
        align-items: center;
        width: 120px;
        background-color: #0F172A;
        border-radius: 100px;
      }
    }
    
  }
  .backgroup-dropdown.package {
    padding: 20px 20px 60px 20px;
  }

  .backgroup-dropdown.maketplace-date {
    width: 420px !important;
  }
}
.edit-item-shipment-entry {
  color: $colorPrimary;
  font-weight: 400;
  font-size: 14px;
  // margin-left: 10px;
  padding: 10px;
}

.tooltip-addr{
  max-width:600px !important;
}

:root {
  --dispatch-green: #238552;
  --dispatch-blue: #1e40af;
  --dispatch-blue2: #2d72d2;
  --dispatch-yellow: #f8c60d;
  --dispatch-black: #0f172a;
  --dispatch-grey: #6f6f6f;
  --dispatch-red: #CD4246;
}
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}


.custom-select-multiple {
  .ant-select-dropdown {
    > div {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .ant-select-item-option {
    .ant-select-item-option-state {
      display: none !important;
    }
    .ant-select-item-option-content {
      padding-left: 20px;

      &::after {
        content: "";
        background-image: url('/assets/svg/icon-checkbox-input.svg');
        position: absolute;
        width: 16px;
        height: 16px;
        left: 10px;
        bottom: 8px;
      }
    }
    &.ant-select-item-option-selected {
      .ant-select-item-option-content {
        &::after {
          content: "";
          background-image: url('/assets/svg/icon-checked.svg');
        }
      }
    }
  }
}